import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Card,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";

// Extracted Form Fields Configuration
const formFields = [
  { id: "name", label: "Name", xs: 12, sm: 6, autoComplete: "name" },
  { id: "email", label: "Email", type: "email", xs: 12, sm: 6, autoComplete: "email" },
  { id: "phone", label: "Phone", type: "tel", xs: 12, sm: 6, autoComplete: "tel" },
  { id: "password", label: "Password", type: "password", xs: 12, autoComplete: "new-password" },
  { id: "street", label: "Street Address", xs: 12, sm: 6, autoComplete: "street-address" },
  { id: "city", label: "City", xs: 12, sm: 6, autoComplete: "address-level2" },
  { id: "state", label: "State", xs: 12, sm: 6, autoComplete: "address-level1" },
  { id: "zip", label: "Zip Code", xs: 12, sm: 6, autoComplete: "postal-code" },
];

// Custom styling using styled API
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#fff",
}));

const StyledForm = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const CustomerSignup = () => {
  // Form state
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  // Other states
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Router hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { admin } = useParams();

  // Extract session_id from query params
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const sessionId = queryParams.get("session_id");

  useEffect(() => {
    if (!sessionId) {
      setErrorMessage("No payment session found. Please complete the payment first.");
    }
  }, [sessionId]);

  // Handle input changes
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  // Geocoding API call to convert address to coordinates
  const getCoordinates = useCallback(async () => {
    const { street, city, state, zip } = formData;
    const address = `${street}, ${city}, ${state}, ${zip}`;
    const KEY = process.env.REACT_APP_GEO_API_KEY;

    if (!KEY) {
      throw new Error("API key is not defined. Please check your .env file.");
    }

    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          address
        )}&key=${KEY}`
      );

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry;
        return `${lat},${lng}`;
      } else {
        throw new Error("No coordinates found for the given address.");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      throw new Error("Unable to get coordinates. Please check the address.");
    }
  }, [formData]);

  // Form validation
  const validate = useCallback(() => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required.";
    else {
      // Basic email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Invalid email format.";
      }
    }

    if (!formData.password) newErrors.password = "Password is required.";
    else if (formData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters.";

    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    else {
      // Basic phone number validation
      const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format
      if (!phoneRegex.test(formData.phone)) {
        newErrors.phone = "Invalid phone number format.";
      }
    }
    if (!formData.street) newErrors.street = "Street address is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.zip) newErrors.zip = "Zip code is required.";
    else {
      // Basic zip code validation (US)
      const zipRegex = /^\d{5}(-\d{4})?$/;
      if (!zipRegex.test(formData.zip)) {
        newErrors.zip = "Invalid zip code format.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  // Handle form submission
  const handleSignup = useCallback(
    async (e) => {
      e.preventDefault();

      setSuccessMessage("");
      setErrorMessage("");

      // Validate form
      if (!validate()) return;

      setLoading(true);

      try {
        // Get coordinates from address
        const coordinates = await getCoordinates();

        // Send signup request
        await axios.post("https://api.skilledcrm.com/customer-signup/", {
          ...formData,
          coordinates, // Add coordinates to payload
          session_id: sessionId,
          admin_id: admin,
        });

        setSuccessMessage("Signup successful! Redirecting to login...");
        setLoading(false);

        // Redirect after a short delay to allow users to read the success message
        setTimeout(() => navigate("/login"), 3000);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message || "Error during signup. Please try again.");
        }
      }
    },
    [formData, getCoordinates, sessionId, admin, navigate, validate]
  );

  return (
    <StyledContainer component="main" maxWidth="md">
      <Card sx={{ padding: { xs: 2, sm: 4 }, boxShadow: 3 }}>
        <Typography component="h1" variant="h5" align="center" gutterBottom>
          Customer Signup
        </Typography>

        <Divider sx={{ my: 3 }} />

        {/* Success Message */}
        {successMessage && (
          <Alert
            severity="success"
            onClose={() => setSuccessMessage("")}
            sx={{ mb: 2 }}
          >
            {successMessage}
          </Alert>
        )}

        {/* Error Message */}
        {errorMessage && (
          <Alert
            severity="error"
            onClose={() => setErrorMessage("")}
            sx={{ mb: 2 }}
          >
            {errorMessage}
          </Alert>
        )}

        <StyledForm component="form" noValidate onSubmit={handleSignup}>
          <Grid container spacing={3}>
            {formFields.map(
              ({ id, label, type = "text", xs, sm, autoComplete }) => (
                <Grid item xs={xs} sm={sm} key={id}>
                  <TextField
                    required
                    fullWidth
                    id={id}
                    label={label}
                    name={id}
                    type={type}
                    value={formData[id]}
                    onChange={handleChange}
                    error={Boolean(errors[id])}
                    helperText={errors[id]}
                    autoComplete={autoComplete}
                    variant="outlined"
                    InputLabelProps={{ htmlFor: id }}
                  />
                </Grid>
              )
            )}
          </Grid>

          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || !sessionId}
            sx={{ mt: 4, py: 1.5 }}
            startIcon={
              loading && <CircularProgress size={20} color="inherit" />
            }
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </StyledButton>
        </StyledForm>
      </Card>
    </StyledContainer>
  );
};

export default CustomerSignup;
