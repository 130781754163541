import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SidebarNav } from './components';
import { UserContext } from '../../../../UserProvider'; // Update path to UserContext

const Sidebar = ({ pages, open, variant, onClose }) => {
  const { isLoggedIn, logout } = useContext(UserContext);

  // Retrieve the 'admin' flag from localStorage and parse it to boolean
  const isAdmin = localStorage.getItem('admin') === 'true';

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 1,
        }}
      >
        {/* Navigation items */}
        <SidebarNav pages={pages} />

        {/* Spacer to push login/logout buttons to the bottom */}
        <Box flexGrow={1} />

        {/* Conditional rendering based on login status */}
        <Box sx={{ padding: 1 }}>
          {isLoggedIn ? (
            <>
              {/* Dashboard Button */}
              <Button
                fullWidth
                variant="text"
                color="inherit"
                href={isAdmin ? '/admin-dashboard' : '/customer-dashboard'}
                sx={{ marginBottom: 1 }}
              >
                Dashboard
              </Button>

              {/* Logout Button */}
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={logout}
              >
                Logout
              </Button>
            </>
          ) : (
            /* Login Button */
            <Button
              fullWidth
              variant="contained"
              color="primary"
              href="/login"
            >
              Login
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired,
};

export default Sidebar;
