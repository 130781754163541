import React, { useContext } from 'react'; // Import useContext
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import Logo from '../../../../logo.png';  // Import the PNG logo
import { UserContext } from '../../../../UserProvider'; // Update the path to your UserContext

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  logoImage: {
    height: '80px',  // Adjust logo height as needed
    width: 'auto',
  },
});

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const classes = useStyles();
  const { isLoggedIn, logout } = useContext(UserContext);

  // Retrieve the 'admin' flag from localStorage and parse it to boolean
  const isAdmin = localStorage.getItem('admin') === 'true';

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Skilled Strategies"
        className={classes.logo}
      >
        <img src={Logo} alt="Skilled Strategies Logo" className={classes.logoImage} />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {/* Conditionally render buttons based on login status */}
        {isLoggedIn ? (
          <>
            {/* Dashboard Button */}
            <Box marginLeft={4}>
              <Button
                variant="text"
                color="inherit"
                component="a"
                href={'/customer-dashboard'}
                size="large"
                style={{ color: 'black' }}
              >
                Dashboard
              </Button>
            </Box>
            {/* Logout Button */}
            <Box marginLeft={4}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={logout}
                size="large"
              >
                Logout
              </Button>
            </Box>
          </>
        ) : (
          <>
            {/* Login Button */}
            <Box marginLeft={4}>
              <Button
                variant="contained"
                color="primary"
                component="a"
                href="/login"
                size="large"
              >
                Login
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
