import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const img1 = require('../../../../img2.jpeg'); // Using require to load image

const VideoSection = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={4} alignItems="center">
      {/* Image Section */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            component="img"
            src={img1}
            alt="Reliable Trash Pickup and Waste Removal Services in Goldsboro, NC"
            sx={{
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '700px', // Adjust this value to lower the image height
              borderRadius: '10px',
            }}
          />
        </Box>
      </Grid>

      {/* Text Section */}
      <Grid item xs={12} md={6}>
        <Box>
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ fontWeight: 700, mb: 2 }}
          >
            Reliable Trash Pickup and Waste Removal in Goldsboro, NC
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ mb: 2 }}
          >
            Cardinal Bins offers dependable trash pickup and waste removal
            services for homes and businesses in Goldsboro, NC. We provide
            regular trash collection, bulk waste disposal, and specialized waste
            management solutions tailored to meet your needs.
          </Typography>
          <Typography variant="h6" component="p" color="text.secondary">
            Our team is committed to keeping your property clean and waste-free.
            Whether it's weekly pickups or a one-time bulk trash removal,
            Cardinal Bins ensures prompt, professional service you can rely on.
            Let us handle the waste so you can enjoy a cleaner, greener
            environment in Goldsboro.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoSection;