import React from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
    About,
    InfoWithImage2,
    Features,
    Team,
    VideoSection,
    Hero
} from './components';

const Service = () => {
    const theme = useTheme();
    return (
        <Main>
                <Box
        bgcolor={'alternate.main'}
        sx={{
          position: 'relative',
          '&::after': {
            position: 'absolute',
            content: '""',
            width: '30%',
            zIndex: 1,
            top: 0,
            left: '5%',
            height: '100%',
            backgroundSize: '18px 18px',
            backgroundImage: `radial-gradient(${alpha(theme.palette.primary.dark, 0.4)} 20%, transparent 20%)`,
            opacity: 0.2,
          },
        }}
      >
        <Box position={'relative'} zIndex={3}>
          <Hero />
        </Box>
      </Box>
            <Container>
                <VideoSection />
            </Container>
            {/*<Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>*/}
            {/*    <Container>*/}
            {/*        <Advantages />*/}
            {/*    </Container>*/}
            {/*</Box>*/}
            <Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>
                <Container>
                    <Features/>
                </Container>
            </Box>

            <Container>
                <InfoWithImage2 />
            </Container>
            <Divider />
            {/*<Container>*/}
            {/*    <Reviews />*/}
            {/*</Container>*/}
            {/*<Container paddingTop={'0 !important'}>*/}
            {/*    <Integrations />*/}
            {/*</Container>*/}
            <Box bgcolor={theme.palette.alternate?.main || '#f7faff'}>
                <Container>
                    <Team />
                </Container>
            </Box>
        </Main>
    );
};

export default Service;