import { useContext, useState } from 'react';
import { TextField, Button, Typography, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import { Box } from '@mui/system';
import axios from 'axios';

const LoginForm = ({ toggleForm }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, setIsLoggedIn, setToken } = useContext(UserContext);

  const styles = {
    error: {
      color: 'red',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    login(email, password, setError, navigate);
  };

  return (
    <Container component="main" maxWidth="xs" style={{ height: '100vh' }}>
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <div>
          <Typography variant="h4" component="h1" align="center">
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error !== '' && <p style={styles.error}>{error}</p>}
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
              Login
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Button onClick={toggleForm} sx={{ mt: 3, mb: 2 }}>
                  Don't have an account? Sign Up
                </Button>
              </Grid>
            </Grid>
          </form>
          {/*/!* Google Login Button *!/*/}
          {/*<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>*/}
          {/*  <Box display="flex" justifyContent="center">*/}
          {/*    <GoogleLogin onSuccess={handleGoogleSuccess} onError={handleGoogleError} />*/}
          {/*  </Box>*/}
          {/*</GoogleOAuthProvider>*/}
        </div>
      </Grid>
    </Container>
  );
};

export default LoginForm;
