import { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import {Main} from "../layouts";

const CustomerDashboard = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const response = await axios.get('https://api.skilledcrm.com/customer-dashboard/', {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        });
        setSubscriptionDetails(response.data);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
      } finally {
        setLoading(false);
      }
    };



    fetchSubscriptionDetails();
  }, []);

  // Format the date using date-fns
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return format(new Date(dateString), 'PPP, p'); // Example: Jan 1, 2024, 5:00 PM
  };

  return (
      <Main>
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Customer Dashboard
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : subscriptionDetails ? (
        <Grid container spacing={3}>
          {/* Next Charge Date Card */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Next Charge Date
                </Typography>
                <Typography variant="body1">
                  {formatDate(subscriptionDetails.next_payment)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Total Paid Card */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total Paid
                </Typography>
                <Typography variant="body1">
                  ${subscriptionDetails.total_paid}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Last Payment Card */}
          <Grid item xs={12} sm={6}>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Last Payment Date
                </Typography>
                <Typography variant="body1">
                  {formatDate(subscriptionDetails.last_payment)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body1" align="center">
          No subscription details available.
        </Typography>
      )}
    </Container>
      </Main>
  );
};

export default CustomerDashboard;
