import React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useNavigate } from 'react-router-dom';

// Example team members (update this array with real team member info)
const mock = [
    {
        name: 'John Doe',
        title: 'Chief Operations Officer',
        avatar: '', // Provide a valid image path
        email: 'jdoe@cardinalbins.com',
    },
    {
        name: 'Jane Smith',
        title: 'Chief Financial Officer',
        avatar: '', // Provide a valid image path
        email: 'jsmith@cardinalbins.com',
    },
    {
        name: 'Samuel Green',
        title: 'Customer Service Manager',
        avatar: '', // Provide a valid image path
        email: 'sgreen@cardinalbins.com',
    },
];

const Reviews = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'secondary'}
                    align={'center'}
                >
                    Meet Our Team
                </Typography>
                <Typography
                    variant="h4"
                    align={'center'}
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                        marginTop: theme.spacing(1),
                    }}
                >
                    Why Choose Cardinal Bins
                </Typography>
                <Typography
                    variant="h6"
                    align={'center'}
                    color={'text.secondary'}
                    data-aos={'fade-up'}
                >
                    We are a dedicated team committed to providing top-notch trash removal and waste management services. Get to know the people behind Cardinal Bins.
                </Typography>
                <Box marginTop={2} display={'flex'} justifyContent={'center'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => { navigate('/contact'); }}
                        startIcon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                width={20}
                                height={20}
                            >
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                        }
                    >
                        Contact Us
                    </Button>
                </Box>
            </Box>

            {/*<Box display="flex" justifyContent="center">*/}
            {/*    <Grid container spacing={2} justifyContent="center">*/}
            {/*        {mock.map((item, i) => (*/}
            {/*            <Grid item xs={12} sm={6} md={4} key={i} display="flex" justifyContent="center">*/}
            {/*                <Box*/}
            {/*                    sx={{*/}
            {/*                        paddingBottom: 2,*/}
            {/*                        display: 'flex',*/}
            {/*                        flexDirection: 'column',*/}
            {/*                        alignItems: 'center'*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <ListItem*/}
            {/*                        component="div"*/}
            {/*                        disableGutters*/}
            {/*                        sx={{*/}
            {/*                            padding: 5,*/}
            {/*                            display: 'flex',*/}
            {/*                            flexDirection: 'column',*/}
            {/*                            alignItems: 'center',*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        <ListItemAvatar sx={{ marginBottom: 2 }}>*/}
            {/*                            <Avatar*/}
            {/*                                src={item.avatar}*/}
            {/*                                variant={'rounded'}*/}
            {/*                                sx={{*/}
            {/*                                    width: 100,*/}
            {/*                                    height: 100,*/}
            {/*                                    borderRadius: 2,*/}
            {/*                                }}*/}
            {/*                            />*/}
            {/*                        </ListItemAvatar>*/}
            {/*                        <ListItemText*/}
            {/*                            sx={{ textAlign: 'center' }}*/}
            {/*                            primary={item.name}*/}
            {/*                            secondary={*/}
            {/*                                <>*/}
            {/*                                    {item.title}*/}
            {/*                                    <br />*/}
            {/*                                    {item.email}*/}
            {/*                                </>*/}
            {/*                            }*/}
            {/*                            primaryTypographyProps={{ fontWeight: 700 }}*/}
            {/*                            secondaryTypographyProps={{ color: 'text.secondary' }}*/}
            {/*                        />*/}
            {/*                    </ListItem>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*        ))}*/}
            {/*    </Grid>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default Reviews;