import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import logo from '../../../../logo.png'; // Adjust path as per your project structure

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={1}
              flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box
                display={'flex'}
                component="a"
                href="/"
                title="Cardinal Bins - Trash Pickup Services in Goldsboro, NC"
                width={80}
            >
              <Box
                  component={'img'}
                  src={logo} // Using local logo image here
                  height={1}
                  width={1}
                  alt="Cardinal Bins - Trash Pickup Services in Goldsboro, NC"
              />
            </Box>
            <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
              <Box marginTop={1} marginRight={2}>
                <Link
                    underline="none"
                    component="a"
                    href="/"
                    color="text.primary"
                    variant={'subtitle2'}
                    title="Home - Cardinal Bins, Goldsboro, NC"
                >
                  Home
                </Link>
              </Box>
              <Box marginTop={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    component="a"
                    href="/contact"
                    size="small"
                    title="Contact Cardinal Bins, Trash Pickup Services, Goldsboro, NC"
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
              align={'center'}
              variant={'subtitle2'}
              color="text.secondary"
              gutterBottom
          >
            &copy; {new Date().getFullYear()} Cardinal Bins, Goldsboro, NC. All rights reserved.
          </Typography>
          <Typography
              align={'center'}
              variant={'caption'}
              color="text.secondary"
              component={'p'}
          >
            Cardinal Bins is dedicated to keeping your home or business waste-free. We proudly provide trash pickup and waste removal services to Goldsboro, NC, and surrounding areas.
          </Typography>
          <Typography
              align={'center'}
              variant={'caption'}
              color="text.secondary"
              component={'p'}
          >
            By using our website, you agree to our cookie policy for a faster, safer experience and for marketing purposes. Contact us today for reliable trash collection and waste management services in Goldsboro, NC.
          </Typography>
        </Grid>
      </Grid>
  );
};

export default Footer;