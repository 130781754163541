import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import ContactUsImg from '../../ContactUs.jpg';
import Typography from "@mui/material/Typography";

const ContactUs = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const Sidebar = () => (
        <Box
            flex={'1 1 30%'}
            maxWidth={'30%'}
            maxHeight={'100vh'}
            position={'sticky'}
            top={0}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                height={1}
                width={1}
            >
                <Box
                    component={'img'}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={"https://i.ibb.co/CBdGRKR/trash-Truck.jpg"}
                    alt="Contact Cardinal Bins - Trash Pickup Services"
                    sx={{
                        objectFit: 'cover',
                    }}
                />
            </Box>
        </Box>
    );

    return (
        <Main>
            <Box
                position={'relative'}
                minHeight={'100vh'}
                display={'flex'}
                marginTop={-13}
            >
                {isMd ? <Sidebar /> : null}
                <Box
                    flex={{ xs: '1 1 100%', md: '1 1 70%' }}
                    maxWidth={{ xs: '100%', md: '70%' }}
                    paddingTop={14}
                >
                    <Box height={1}>
                        <Container>
                            <Box>
                                <Typography
                                    variant="h4"
                                    color="text.primary"
                                    sx={{ fontWeight: 700, marginBottom: 2 }}
                                >
                                    Contact Cardinal Bins
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="p"
                                    color="text.secondary"
                                    sx={{ marginBottom: 3 }}
                                >
                                    Get in touch with us today for reliable trash pickup and waste removal services!
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="p"
                                    color="text.primary"
                                    sx={{ marginBottom: 2 }}
                                >
                                    <strong>Phone Number:</strong> (919) 581-7770
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="p"
                                    color="text.primary"
                                    sx={{ marginBottom: 2 }}
                                >
                                    <strong>Hours of Operation:</strong>
                                    <br />
                                    Monday - Friday: 8:00 AM - 6:00 PM
                                    <br />
                                    Saturday: 9:00 AM - 4:00 PM
                                    <br />
                                    Sunday: Closed
                                </Typography>
                                <Typography
                                    variant="h6"
                                    component="p"
                                    color="text.secondary"
                                >
                                    We are happy to assist you with any inquiries or to schedule a trash pickup. Feel free to call us during our business hours.
                                </Typography>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Main>
    );
};

export default ContactUs;