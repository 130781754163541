import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const img2 = require('../../../../img1.jpeg'); // Using require to load image

const InfoWithImage2 = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={4} alignItems="center">
      {/* Text on the left */}
      <Grid item xs={12} md={6}>
        <Box sx={{ paddingTop: 3 }}>
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ fontWeight: 700, mb: 2 }}
          >
            Keeping Goldsboro Clean, One Pickup at a Time
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ mb: 3 }}
          >
            At Cardinal Bins, we take pride in offering reliable trash removal
            and waste management solutions. Our mission is to provide hassle-free
            service that helps maintain clean and green neighborhoods across
            Goldsboro, NC.
          </Typography>
          <Typography variant="h6" component="p" color="text.secondary">
            From regular trash collection to special waste handling, we’ve got
            you covered. Our commitment to quality service ensures that your
            home or business stays neat and organized without the stress. Let
            us do the dirty work while you enjoy a cleaner, healthier environment.
          </Typography>
        </Box>
      </Grid>

      {/* Image on the right */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            component="img"
            src={img2}
            alt="Dependable Trash and Waste Services in Goldsboro, NC"
            sx={{
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '700px', // Adjust the maxHeight as needed
              borderRadius: '10px',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default InfoWithImage2;