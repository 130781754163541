/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Container from '../../../../components/Container';

// MUI Icons
import DeleteIcon from '@mui/icons-material/Delete';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';

const mock = [
    {
        title: 'Reliable Trash Pickup in Goldsboro, NC',
        subtitle:
            'Cardinal Bins provides dependable trash pickup services for residents and businesses in Goldsboro, NC. Our team ensures timely and consistent waste removal, keeping your property clean and waste-free. With affordable and reliable trash collection services, you can trust us to manage your waste efficiently.',
        icon: <DeleteIcon fontSize="large" />,
    },
    {
        title: 'Bulk Waste Removal in Goldsboro, NC',
        subtitle:
            'Cardinal Bins offers bulk waste removal services for larger trash loads and one-time cleanups. Whether you are doing a major clean-out or have bulky items like furniture and appliances, we can handle it all. Serving Goldsboro, NC, we make it easy to dispose of large and heavy items without the hassle.',
        icon: <HomeRepairServiceIcon fontSize="large" />,
    },
    {
        title: 'Weekly Trash Collection for Goldsboro Residents',
        subtitle:
            'Keep your home waste-free with Cardinal Bins’ weekly trash collection services. Our dedicated team provides regular trash pickups to Goldsboro residents, ensuring your trash is collected on time, every time. We offer convenient, affordable options to fit your household’s waste management needs.',
        icon: <CalendarTodayIcon fontSize="large" />,
    },
    {
        title: 'Trash Pickup for Goldsboro Businesses',
        subtitle:
            'Cardinal Bins supports local businesses in Goldsboro, NC, with commercial trash pickup services. From office buildings to retail stores, our reliable waste removal services ensure your business stays clean and organized. Reach out today to schedule a pickup or learn more about our flexible waste management plans for businesses.',
        icon: <BusinessIcon fontSize="large" />,
    },
];

const Features = () => {
    const theme = useTheme();

    return (
        <Box sx={{ position: 'relative' }}>
            <Container>
                <Box>
                    <Box marginBottom={4}>
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'medium',
                            }}
                            gutterBottom
                            color={'text.secondary'}
                            align={'center'}
                        >
                            Why Choose Cardinal Bins
                        </Typography>
                        <Typography
                            variant="h4"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            Professional Trash Pickup Services in Goldsboro, NC
                        </Typography>
                        <Typography variant="h6" align={'center'} color={'text.secondary'}>
                            Cardinal Bins provides reliable and affordable trash pickup and waste removal services
                            tailored to meet the specific needs of Goldsboro residents and businesses.
                            <br />
                            Whether you need regular trash collection, bulk waste disposal, or commercial services,
                            <br />
                            we are committed to keeping your property clean and waste-free in Goldsboro, NC.
                        </Typography>
                    </Box>
                    <Grid container spacing={4}>
                        {mock.map((item, i) => (
                            <Grid key={i} item xs={12} md={3}>
                                <ListItem
                                    component="div"
                                    disableGutters
                                    sx={{
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        padding: 0,
                                    }}
                                >
                                    <Box
                                        component={ListItemAvatar}
                                        marginBottom={1}
                                        minWidth={'auto !important'}
                                    >
                                        <Box color={theme.palette.primary.main}>{item.icon}</Box>
                                    </Box>
                                    <ListItemText
                                        primary={item.title}
                                        secondary={item.subtitle}
                                        primaryTypographyProps={{
                                            variant: 'h6',
                                            gutterBottom: true,
                                            align: 'left',
                                        }}
                                        secondaryTypographyProps={{ align: 'left' }}
                                        sx={{
                                            '& .MuiListItemText-primary': {
                                                fontWeight: 700,
                                            },
                                            margin: 0,
                                        }}
                                    />
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default Features;