import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Page from "./components/Page";
import Service from './views/Service/Service';
import React from 'react';
import ContactUs from './views/ContactPage';
import CustomerSignup from "./views/CustomerSignup";
import Payment from "./views/Payment";

import CustomerDashboard from "./views/CustomerDashboard";
import Login from "./views/Login";
function App() {
  return (
    <Page>
      <Router>
          <Routes>
              <Route path="/" element={<Service />} />
              <Route path={"/contact"} element={<ContactUs />} />
            {/*<Route path="/signup" element={<SignUp />} />*/}

              <Route path="/customer-signup/:admin" element={<CustomerSignup />} />
              <Route path={'payment/:usr'} element={<Payment/>} />
              <Route path="/customer-dashboard" element={<CustomerDashboard />} />
            <Route path={"/login"} element={<Login/>} />
              {/*<Route path="/login" element={<Login />} />*/}

            {/* Catch-All Route: Redirect to Home if no match is found */}
            <Route path="*" element={<Navigate to="/" replace />} />
              {/*<Route path={"/products"} element={<Pricing />} />*/}
          </Routes>

      </Router>
    </Page>
  );
}

export default App;