import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';

const CtaSection = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box>
            <Typography
                sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                }}
                gutterBottom
                color={'text.secondary'}
            >
                Trash Pickup
            </Typography>
            <Box marginBottom={2}>
                <Typography
                    variant="h2"
                    color="text.primary"
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    Keeping Your Neighborhood Clean with{' '}
                    <Typography
                        color={'primary'}
                        component={'span'}
                        variant={'inherit'}
                        sx={{
                            background: `linear-gradient(180deg, transparent 82%, ${alpha(
                                theme.palette.secondary.main,
                                0.3,
                            )} 0%)`,
                        }}
                    >
                        Reliable Service
                    </Typography>
                </Typography>
            </Box>
            <Box marginBottom={3}>
                <Typography variant="h6" component="p" color="text.secondary">
                    Our mission is to make waste removal simple and stress-free for you.
                    Whether it's weekly pickups or special requests, we've got you covered.
                    Let us take care of your trash so you can focus on what matters most.
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={isMd ? false : true}
                    href={'/contact'}
                >
                    Schedule a Pickup
                </Button>
            </Box>
        </Box>
    );
};

export default CtaSection;